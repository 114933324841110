
import {request} from "@/network/request.js";
import qs from 'qs';

export default{
  // 查询-条件查询订单收款汇总信息
  findOrderReceiptReport(data){
    return request({
      method:'POST',
      url:'/report/orderReceipt/findOrderReceiptReport',
      data
    })
  },
  //查询-条件查询订单收款统计列表信息
  findOrderReceiptReportList(data){
    return request({
      method:'POST',
      url:'/report/orderReceipt/findOrderReceiptReportList',
      data
    })
  }
}